import React, {useMemo, useRef} from "react";
import {useSprings, animated} from "react-spring";
import "./Thanks.css";
import image1 from "../../images/image 2.svg";

export const Thanks = () => {
  const ref = useRef(null);

  const content =
    "Chào tất cả mọi người, Chúng tớ xin gửi lời cảm ơn chân thành đến tất cả các bạn đã dành thời gian đến tham dự đám cưới của mình. Sự hiện diện của các bạn đã làm cho ngày trọng đại của tôi và người ấy trở nên đặc biệt hơn rất nhiều.";

  const array = content.split(" ");

  const [animationProps, setAnimationProps] = useSprings(array.length, () => ({
    opacity: 0,
    config: {duration: 500},
  }));

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setAnimationProps((index) => ({
            opacity: 1,
            delay: index * 50,
          }));
          observer.disconnect();
        }
      },
      {threshold: 0.5}
    );
    observer.observe(ref.current);
  }, [setAnimationProps, ref]);

  return (
    <div className="thanks py-20">
      <div className="thanks-container flex w-11/12 md:w-3/5 m-auto justify-between">
        <div className="thanks-text w-3/5 md:w-1/2">
          <div className="thanks-title text-6xl md:text-7xl">Lời Cảm Ơn</div>
          <div className="thanks-content mt-10" ref={ref}>
            {animationProps.map((style, index) => {
              return (
                <>
                  <animated.div
                    key={index}
                    style={style}
                    className="content inline-block mx-0.5">
                    {array[index]}
                  </animated.div>
                  {index === 4 ? <br /> : null}
                </>
              );
            })}
          </div>
        </div>
        <div className="thanks-qr">
          <div className="thanks-title text-6xl md:text-7xl">Mừng Cưới</div>
          <div className="thanks-code">
            <img
              src={image1}
              alt="weeding-pic4"
              className="w-32 mx-auto mt-10"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
