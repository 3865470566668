import React, { useEffect, useMemo, useRef } from 'react'
import {useSpring, animated} from "react-spring";

export const Fadeup = ({children, ltr = false, duration= 1000, threshold = 0}) => {
  const ref = useRef(null);

  const [springProps, setSpringProps] = useSpring(() => ({
    opacity: 0,
    transform: ltr ? "translateX(50px)" : "translateY(50px)",
    config: {duration: duration},
  }));

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setSpringProps({opacity: 1, transform: "translate(0px, 0px)"});
            observer.disconnect();
          }
        },
        {threshold: threshold}
      ),
    [setSpringProps]
  );

  useEffect(() => {
    observer.observe(ref.current);
  }, [observer]);

  return (
    <animated.div ref={ref} style={springProps} className="content">
      {children}
    </animated.div>
  );
};
