import React, {useRef, useEffect, useMemo} from "react";
import {useSpring, animated} from "react-spring";
import openningImage from "../../images/JER_9301.jpg";
import {Fadeup} from "../FadeUp/Fadeup";
import "./Openning.css";

export const Opening = () => {
  // const ref = useRef(null);

  // const [springProps, setSpringProps] = useSpring(() => ({
  //   opacity: 0,
  //   transform: "translateY(50px)",
  //   config: {duration: 1000},
  // }));

  // const observer = useMemo(
  //   () =>
  //     new IntersectionObserver(
  //       ([entry]) => {
  //         if (entry.isIntersecting) {
  //           setSpringProps({opacity: 1, transform: "translateY(0px)"});
  //           observer.disconnect();
  //         }
  //       },
  //       {threshold: 0.5}
  //     ),
  //   [setSpringProps]
  // );

  // useEffect(() => {
  //   observer.observe(ref.current);
  // }, [observer]);

  return (
    <div
      className="openning h-auto pt-16 relative text-center"
      style={{backgroundImage: `url(${openningImage})`}}>
      <div className="openning-text z-10 relative h-full flex flex-col tracking-widest justify-between items-center">
        <div className="shortName text-5xl font-thin flex-grow-0 mb-28">
          T | C
        </div>
        <Fadeup>
          <div className="fullName sm:text-5xl font-normal">
            <div className="flex flex-col">
              <p className="md:inline-block">Phạm Tiến</p>
              <p className="and sm:pt-2 sm:pb-14 md:px-6 md:inline-block">
                &amp;
              </p>
              <p className="md:inline-block">Linh Chi</p>
            </div>
          </div>
        </Fadeup>
        <div className="date text-4xl font-normal mt-6">16 . 02 . 2023</div>
      </div>
      <div className="transition h-80 relative z-10"></div>
      <div className="overlay"></div>
    </div>
  );
};
