import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./Fonts/Fz-The-Handwritten-Watermark.ttf";
import "@fortawesome/fontawesome-free/css/all.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

