import {Opening} from "./Component/Openning/Opening";
import {Helmet} from "react-helmet";
import {Time} from "./Component/Time/Time";
import {Schedule} from "./Component/Schedule/Schedule";
import Picture from "./Component/Pictures/Picture";
import {Ending} from "./Component/Ending/Ending";
import {Thanks} from "./Component/Thanks/Thanks";
import {Introduction} from "./Component/Introduction/Introduction";
import {Prewedding} from "./Component/Prewedding/Prewedding";
import {Weddingday} from "./Component/Weddingday/weddingday";

function App() {
  return (
    <div className="App">
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,300;0,400;0,500;0,800;1,600&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat+Alternates:ital,wght@0,100;0,300;0,400;0,500;0,800;1,600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Opening />
      <Time />
      <Introduction />
      {/* <Schedule /> */}
      <Prewedding />
      <Picture />
      <Weddingday />
      <Thanks />
      <Ending />
    </div>
  );
}

export default App;
