import React from 'react'
import "./Ending.css"

export const Ending = () => {
  return (
    <div className="ending-container pb-14 pt-20">
      <p className="ending-text text-7xl text-center">
        Cảm ơn vì đã yêu thuơng chúng tớ <br />{" "}
        <i class="fa-regular fa-heart"></i>
        <i class="fa-solid fa-heart"></i>
        <i class="fa-regular fa-heart"></i>
        <i class="fa-solid fa-heart"></i>
      </p>
    </div>
  );
}
