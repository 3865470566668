import React, {Component} from "react";
import "./Picture.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import wedding1 from "../../images/wed1.jpg";
import wedding2 from "../../images/wed2.jpg";
import wedding3 from "../../images/wed3.jpg";
import wedding4 from "../../images/wed4.jpg";
import wedding5 from "../../images/pic2.jpg";
import wedding6 from "../../images/wed6.jpg";

function PrevArrow(props) {
  const {className, onClick} = props;
  return (
    <div className={className} onClick={onClick}>
      <i class="fa-solid fa-left-long"></i>
    </div>
  );
}
function NextArrow(props) {
  const {className, onClick} = props;
  return (
    <div className={className} onClick={onClick}>
      <i class="fa-solid fa-right-long"></i>
    </div>
  );
}

export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      selected: 0,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(index) {
    this.setState({selected: index});
  }

  next() {
    this.slider1.slickNext();
  }
  previous() {
    this.slider1.slickPrev();
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const {selected} = this.state;

    return (
      <div className="picture-container pb-14 relative">
        <div className="picture-header py-16">
          <p className="picture-heade-text text-5xl text-center">
            <i>Tiến rước Chi về dinh nè</i>
          </p>
        </div>
        <div class="frame py-14">
          <div className="frame-top">
            <div className="line1 mb-1.5"></div>
            <div className="line2"></div>
          </div>
          <div className="frame-bottom">
            <div className="line2 mb-1.5"></div>
            <div className="line1"></div>
          </div>
          <div className="frame-right">
            <div className="line1 mb-2.5"></div>
            <div className="line2"></div>
          </div>
          <div className="frame-left">
            <div className="line2 mb-2.5"></div>
            <div className="line1"></div>
          </div>
          <Slider
            asNavFor={this.state.nav2}
            ref={(slider) => (this.slider1 = slider)}
            className="slider1"
            fade={true}
            beforeChange={(current, next) => this.handleSelect(next)}
            arrows={false}
            autoplay={true}
            pauseOnHover={true}
            pauseOnFocus={true}>
            <div>
              <div className="image-slider2-container">
                <img src={wedding1} alt="weeding-pic1" />
              </div>
            </div>
            <div>
              <div className="image-slider2-container">
                <img src={wedding2} alt="weeding-pic2" />
              </div>
            </div>
            <div>
              <div className="image-slider2-container">
                <img src={wedding3} alt="weeding-pic3" />
              </div>
            </div>
            <div>
              <div className="image-slider2-container">
                <img src={wedding4} alt="weeding-pic1" />
              </div>
            </div>
            <div>
              <div className="image-slider2-container">
                <img src={wedding5} alt="weeding-pic2" />
              </div>
            </div>
            <div>
              <div className="image-slider2-container">
                <img src={wedding6} alt="weeding-pic3" />
              </div>
            </div>
          </Slider>
        </div>
        <Slider
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          slidesToShow={6}
          swipeToSlide={true}
          focusOnSelect={true}
          arrows={false}
          responsive={[
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
          ]}
          className="slider2">
          <div className="image-container relative mt-12 px-2">
            <img
              src={wedding1}
              alt="weeding-pic1"
              onClick={() => this.handleSelect(0)}
            />
            <div className={selected === 0 ? "add-border" : "overlay"}></div>
          </div>
          <div className="image-container relative mt-12 px-2">
            <img
              src={wedding2}
              alt="weeding-pic2"
              onClick={() => this.handleSelect(1)}
            />
            <div className={selected === 1 ? "add-border" : "overlay"}></div>
          </div>
          <div className="image-container relative mt-12 px-2">
            <img
              src={wedding3}
              alt="weeding-pic3"
              onClick={() => this.handleSelect(2)}
            />
            <div className={selected === 2 ? "add-border" : "overlay"}></div>
          </div>
          <div className="image-container relative mt-12 px-2">
            <img
              src={wedding4}
              alt="weeding-pic1"
              onClick={() => this.handleSelect(3)}
            />
            <div className={selected === 3 ? "add-border" : "overlay"}></div>
          </div>
          <div className="image-container relative mt-12 px-2">
            <img
              src={wedding5}
              alt="weeding-pic2"
              onClick={() => this.handleSelect(4)}
            />
            <div className={selected === 4 ? "add-border" : "overlay"}></div>
          </div>
          <div className="image-container relative mt-12 px-2">
            <img
              src={wedding6}
              alt="weeding-pic3"
              onClick={() => this.handleSelect(5)}
            />
            <div className={selected === 5 ? "add-border" : "overlay"}></div>
          </div>
        </Slider>
        <div style={{textAlign: "center"}}>
          <PrevArrow
            className={"picture-button-prev text-5xl"}
            onClick={this.previous}
          />
          <NextArrow
            className={"picture-button-next text-5xl"}
            onClick={this.next}
          />
        </div>
      </div>
    );
  }
}
