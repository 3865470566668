import React, {useRef, useMemo} from "react";
import {useSpring, animated} from "react-spring";

function FadeInOnScroll({item}) {
  const ref = useRef(null);

  const [springProps, setSpringProps] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(100px)",
    config: {duration: 1000},
  }));

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setSpringProps({opacity: 1, transform: "translateX(0px)"});
            observer.disconnect();
          }
        },
        {threshold: 0.5}
      ),
    [setSpringProps]
  );

  React.useEffect(() => {
    observer.observe(ref.current);
  }, [observer]);

  return (
    <div>
      <animated.div ref={ref} style={springProps} className="content">
       {item}
      </animated.div>
    </div>
  );
}

export default FadeInOnScroll;
