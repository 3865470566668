import React from "react";
import "./Time.css";
import {AddToCalendarButton} from "add-to-calendar-button-react";

export const Time = () => {
  return (
    <div className="time text-center py-14">
      <div className="time-text font-500">
        <div className="time-day text-4xl py-14">
          <p>FRIDAY</p>
        </div>
        <div className="time-date text-7xl py-14 tracking-widest font-semibold">
          <p>06 . 01 . 2024</p>
        </div>
        <div className="time-location text-4xl py-14">
          <p>
            <span className="restaurant">HOÀNG LỘC, </span>
            <span className="address">30 BÀ TRIỆU</span>
          </p>
          <p>
            <span className="ward">TÂN LỢI, </span>
            <span className="city">BMT</span>
          </p>
        </div>
      </div>
      {/* <div className="time-button py-14 flex justify-center">
        <AddToCalendarButton
          name="Tiến Chi Wedding"
          description="Cảm ơn mọi người đã tới dự đám cưới "
          startDate="2023-04-08"
          startTime="17:15"
          endDate="2023-04-08"
          endTime="17:45"
          timeZone="Australia/Sydney"
          location="Hoàng Lộc"
          options="'Apple','Google'"
          listStyle="overlay"
          buttonStyle="default"
          trigger="click"
          lightMode="bodyScheme"
          size="10|8|6"
          styleLight="--btn-background: #b2d4ef; --font: 'Montserrat Alternates', sans-serif; --base-font-size-l: 50px;"
          language="vi"></AddToCalendarButton>
      </div> */}
    </div>
  );
};
