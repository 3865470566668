import React from "react";
import "./Introduction.css";
import bride from "../../images/bride/bride.jpg";
import arrowtop from "../../images/bride/main-arrow-top.svg";
import brideborder from "../../images/bride/main-border-bride.svg";
import bridedress from "../../images/bride/main-icon-dress.svg";
import groom from "../../images/groom/groom.jpg";
import arrowbottom from "../../images/groom/main-arrow-bottom.svg";
import groomborder from "../../images/groom/main-border-groom.svg";
import groomvest from "../../images/groom/main-icon-vest.svg";
import arrowring from "../../images/groom/main-arrow-rings.svg";
import {Fadeup} from "../FadeUp/Fadeup";

export const Introduction = () => {
  return (
    <Fadeup>
      <div className="introduction pb-14 mx-auto">
        <Fadeup>
          <div className="bride-section flex flex-col md:flex-row mb-96 md:mb-64">
            <div className="bride-picture md:w-3/5">
              <div
                style={{backgroundImage: `url(${bride})`}}
                className="bride-image"></div>
              <img src={brideborder} alt="border" className="bride-border" />
              <img src={bridedress} alt="dress" className="bride-dress" />
              <img src={arrowtop} alt="arrow" className="bride-arrow" />
            </div>
            <div className="bride-content md:w-2/5">
              <p className="text-4xl md:text-2xl md:pb-4 pb-7">Cô dâu</p>
              <p className="text-6xl md:text-4xl md:pb-7 pb-10 tracking-widest">
                Võ Linh Chi
              </p>
              <p className="tracking-wide">
                Hi anh, sau 6 năm yêu xa chỉ gặp nhau qua màn hình điện thoại em
                cứ ngỡ mọi thứ sẽ dang dở nhưng cuối cùng chúng ta cũng có ngày
                hôm nay được làm vợ anh được chia sẻ những thứ mà dường như em
                rất ít thể hiện ra. Chúng ta quen nhau khi còn ngồi trên ghế nhà
                trường. Khi mà trong mắt chúng ta chỉ là bạn. Nhưng mọi thứ
                dường như thay đôi khi anh sắp đi úc. Chúng ta đi chơi với nhau
                nhiều hơn tâm sự cũng nhiều hơn. Rồi không biết từ lúc nào anh
                đã ở trong tâm trí em nhiều hơn
                <br /> <br />
                Cho đến năm 2018. có lẽ là chúng ta trưởng thành hơn cũng có lẽ
                là do chúng ta không thể giữ lời yêu trong lòng được nữa nên
                chúng ta bắt đầu <b>YÊU</b> ♥
              </p>
            </div>
          </div>
        </Fadeup>
        <Fadeup>
          <div className="groom-section flex md:flex-row flex-col-reverse">
            <div className="groom-content md:w-2/5 ">
              <p className="text-4xl md:text-2xl md:pb-4 pb-7">Chú Rể</p>
              <p className="text-6xl md:text-4xl md:pb-7 pb-10 tracking-widest">
                Phạm Tiến
              </p>
              <p className="tracking-wide">
                Tình yêu mình chưa bao giờ là sét đánh cả. nó được xây dựng từ
                lúc hai đứa gặp nhau từ cái ngày cấp 3 qua cả những khoảng thời
                gian làm bạn rồi đêm đó cũng tới. Cảm ơn e đã chọn a đ chọn
                người luôn luôn là sự k chắc chắn, và chông gai. Cảm ơn e đa
                chọn cùng a đi tiếp con đường mà chính anh còn k có niềm tin
                nhưng e thì có. Và mình bắt đầu, 5 năm đối với mọi người có thể
                không ngắn cũng k dài nhưng với a 5 năm xa nhau là cả một kỳ
                tích
                <br /> <br />A yêu e, yêu mọi thứ thuộc về e yêu cái cách e hiểu
                anh dù a chẳng cần nói gì, yêu ánh mắt, nụ cười và cả những trờ
                đùa nhạt nhẽo của e làm a cười nghiêng và quan trọng nhất a yêu
                e vì e đã luôn tin anh và luôn cùng a bước tiếp mà chưa một lần
                buông tay. ♥
              </p>
            </div>
            <div className="groom-picture md:w-3/5">
              <div
                style={{backgroundImage: `url(${groom})`}}
                className="groom-image"></div>
              <img src={groomborder} alt="border" className="groom-border" />
              <img src={groomvest} alt="vest" className="groom-vest" />
              <img src={arrowbottom} alt="arrow" className="arrow-bottom" />
              <img src={arrowring} alt="arrow" className="arrow-ring" />
            </div>
          </div>
        </Fadeup>
      </div>
    </Fadeup>
  );
};
