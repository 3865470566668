import React from "react";
import "./Prewedding.css";

export const Prewedding = () => {
  return (
    <div className="prewedding-container py-14">
      <div className="prewedding-content">
        <div className="prewedding-text py-16">
          <p className="text-5xl text-center pb-2">Prewedding</p>
          <p className="text-5xl text-center">Quà của Nghĩa</p>
        </div>
        <div className="prewedding-video-container">
          <iframe
            src="https://www.youtube.com/embed/b9f7RYdHFng?si=Y2ZgCDLK0qBiwh85"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  );
};
